import React from 'react'
import { Row, Col } from 'antd'

function Footer() {
	return (
		<footer id="footer" className="dark">
			<div className="footer-wrap">
				<Row>
					<Col md={6} sm={24} xs={24}>
						<div className="footer-center">
							<h2>연락처</h2>
							<div>
								<a target="_blank " href="tel:0522466966">
									전화하기: 052)246-6966
								</a>
							</div>
							<div>
								<a>팩스번호: 052)245-6966</a>
							</div>
							<div>
								<a href="mailto:dlghddn112@naver.com">dlghddn112@naver.com</a>
							</div>
						</div>
					</Col>
					<Col md={6} sm={24} xs={24}>
						<div className="footer-center">
							<h2>
							제휴 사이트
							</h2>
							<div>
							<a target="_blank" rel="noopener noreferrer" href="https://findfixer.net/">
									전직경찰탐정협회
								</a>{' '}
							</div>
							<div>
							<a target="_blank" rel="noopener noreferrer" href="https://blog.naver.com/dlghddn112">
									블로그
								</a>{' '}
							</div>
						</div>
					</Col>
					<Col md={8} sm={24} xs={24}>
						<div className="footer-center">
							<h2>
								대표
							</h2>
							<div>
								이홍우
							</div>
							<div>
								울산광역시 남구 법대로 95번길 3, 2층
							</div>
						</div>
					</Col>
					
				</Row>
			</div>
			<Row className="bottom-bar">
				<Col md={4} sm={24} />
				<Col md={20} sm={24}>
					<span
						style={{
							lineHeight: '16px',
							paddingRight: 12,
							marginRight: 11,
							borderRight: '1px solid rgba(255, 255, 255, 0.55)',
						}}>

					</span>
					<span style={{ marginRight: 12 }}>서비스업 | 행정사, 탐정 | 112-07-89457 </span>
					<span style={{ marginRight: 12 }}>
						Copyright © <div>홍은탐정사무소</div>
					</span>
				</Col>
			</Row>
		</footer>
	)
}

export default Footer
