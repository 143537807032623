import React from 'react';
import { Link } from 'bisheng/router';

export default function Header(props) {
  return (
    <header {...props} id="header">
      <Link id="logo" href="/">
        <img alt="logo" src="/images/logo.png" />
        홍은탐정사무소
      </Link>
    </header>
  );
}
