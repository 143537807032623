import React from 'react';
import Header from './Header';
import Banner from './Banner';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Footer from './Footer';
import { enquireScreen } from 'enquire-js';

let isMobile = false;
enquireScreen((b) => {
  isMobile = b;
});
export class Home extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }
  componentDidMount() {
    enquireScreen((b) => {
      this.setState({
        isMobile: !!b,
      })
    })
  }
  
  render() {
    return (
        <div className="page-wrapper home">
          <Header />
          <Banner isMobile={this.state.isMobile} />
          <Page1 isMobile={this.state.isMobile} />
          <Page2 isMobile={this.state.isMobile} />
          <Page3 />
          <Footer />
        </div>
    );
  }
}
