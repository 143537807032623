import React from 'react'
import PropTypes from 'prop-types'
import TweenOne from 'rc-tween-one'
import QueueAnim from 'rc-queue-anim'
import ScrollParallax from 'rc-scroll-anim/lib/ScrollParallax'
import BannerImage from './BannerImage'

const loop = {
	duration: 3000,
	yoyo: true,
	repeat: -1,
}

class Banner extends React.Component {
	static propTypes = {
		className: PropTypes.string,
	}
	static defaultProps = {
		className: 'banner',
	}
	render() {
		const { className, isMobile } = this.props
		return (
			<div className="home-page-wrapper banner-wrapper" id="banner">
				<div className="banner-bg-wrapper">
					<svg width="400px" height="576px" viewBox="0 0 400 576" fill="none">
						<TweenOne component="g" animation={[{ opacity: 0, type: 'from' }, { ...loop, y: 15 }]}>
							<ellipse id="Oval-9-Copy-4" cx="100" cy="100" rx="6" ry="6" stroke="#2F54EB" strokeWidth="1.6" />
						</TweenOne>
						<TweenOne component="g" animation={[{ opacity: 0, type: 'from' }, { ...loop, y: -15 }]}>
							<g transform="translate(200 400)">
								<g style={{ transformOrigin: '50% 50%', transform: 'rotate(-340deg)' }}>
									<rect stroke="#FADB14" strokeWidth="1.6" width="9" height="9" />
								</g>
							</g>
						</TweenOne>
					</svg>
					<ScrollParallax location="banner" className="banner-bg" animation={{ playScale: [1, 1.5], rotate: 0 }} />
				</div>
				<QueueAnim className={`${className} page`} type="alpha" delay={150}>
					{isMobile &&
						<div className="img-wrapper" key="image">
							<BannerImage />
						</div>}
					<QueueAnim className="text-wrapper" key="text" type="bottom">
						<h1 style={{ color: '#003a8c' }}>홍은탐정사무소</h1>
						<p style={{ fontSize: '14px', lineHeight: '22px' }}>
							울산경찰청, 울산검찰청 특별수사부, 법률사무소에서 배우고 익힌 저만의 독특한 실무 경험을 바탕으로, 사건 사실 조사, 민원서류 대행, 증거 확보 녹취록, 소송 증거자료 확보 등의 탐정 업무 영역을
							수행하고 있습니다.
						</p>
					</QueueAnim>
					{!isMobile &&
						<div className="img-wrapper" key="image">
							<ScrollParallax location="banner" component={BannerImage} animation={{ playScale: [1, 1.5], y: 80 }} />
						</div>}
				</QueueAnim>
			</div>
		)
	}
}

export default Banner
